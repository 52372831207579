import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import baseURL from '../../configs/api';
import colorConfigs from '../../configs/colorConfigs';
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import { Box } from '@mui/material';
import useAccessControl from '../hooks/useAccessControl';
import appRoutes from '../../routes/appRoutes';
import { getAccessControl } from '../../services/acessControl';
import { getAllowDenyStatus } from '../../services/allowStatus';

function Login() {
    const { setAuth }: any = useAuth();
    const {setUserRoleId, setPermissions}: any = useAccessControl();

    const navigate = useNavigate();

    const userRef = useRef<HTMLInputElement>(null);
    const errRef = useRef<HTMLParagraphElement>(null);

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    useEffect(() => {
        userRef.current?.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!isProcessing) {
            setIsProcessing(true);
            try {
                const response = await axios.post(`${baseURL}/Auth/login`,
                    JSON.stringify({ userName: user, password: pwd }),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    }
                );
                const jsonData: any = jwtDecode(response?.data);
                const accessToken = response?.data;
                const id = jsonData["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];
                const roles = jsonData["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
                const givenname = jsonData["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname"];
                const emailAddress = jsonData["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"];
                Cookies.set("token-jj-rental-ui", response?.data, {
                    expires: jsonData.exp,
                });
                Cookies.set("role", roles, {
                    expires: jsonData.exp,
                });
                setAuth({ id, user, givenname, emailAddress, roles, accessToken });
                setUser('');
                setPwd('');
                const userId = jsonData["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"]
                getUserData(userId, jsonData.exp, roles, accessToken);
                setIsProcessing(false);
            } catch (err: any) {
                setErrMsg(err.response.data);
                errRef.current?.focus();
                setIsProcessing(false);
            }
        }
    }

    const fetchPermissions = async (role: string, roleId: number, token: string) => {        
            if (role.toLowerCase() === "admin") {
                navigate("/");
                 return;
            }

            try {
                const res: any = await getAccessControl("accessControl", {roleId: roleId}, token.toString());
                const accessControlData = res;
                const response: any = await getAllowDenyStatus();

                const newPermissions: { [key: string]: boolean } = {};
                accessControlData.forEach((item: any) => {
                    const normalizedSection = item.section.replace(/\s+/g, '').replace(/-/g, '').toLowerCase();
                    newPermissions[normalizedSection] = item.allowDenyStatusId === response.filter((statusItem: any) => statusItem.allowDenyName === 'Allow')[0].id;
                });

                setPermissions(newPermissions);
                handleNavigationForAppRoutes(newPermissions);
            } catch (error) {
                console.error(error);
            } 
    };


    const getUserData = (id: string, exp: any, roles: any, token: string) => {
        fetch(`${baseURL}users/${parseInt(id)}`)
            .then((res) => res.json())
            .then((userData) => {
                const roleId = userData.roleId;
                setUserRoleId(roleId);
                Cookies.set("roleId-jj-rental-ui", roleId, {
                    expires: exp,
                });

                fetchPermissions(roles, roleId, token);
                setIsProcessing(false);
            })
            .catch((error) => {
                setIsProcessing(false);
                console.error(error);
            });
    };

    const pages = [
        "dashboarddailymanifest",
        "dashboardbookings",
        "dashboardavailability",
        "dashboardfleet",
        "dashboardextras",
        "dashboardattempts",
      ];

      const handleNavigationForAppRoutes = (permissions: any) => {
        const dashboardRoute = {
            path: "/",
            child: pages.map(page => ({ section: page }))
        };
    
        const hasDashboardPermission = dashboardRoute.child.some((item: any) => {
            const hasPermission = permissions ? permissions[item.section] : false;
            return hasPermission;
        });
    
        if (hasDashboardPermission) {
            navigate("/");
        } else {    
            const nextAvailableRoute = appRoutes.find((route: any) => {
                const hasPermission = permissions ? permissions[route.state] : false;
    
                if (route.child) {
                    const childRoute = route.child.find((child: any) => {
                        const hasChildPermission = permissions ? permissions[child.section] : false;
                        return hasChildPermission;
                    });
                    return childRoute !== undefined;
                }
    
                return hasPermission;
            });
    
            if (nextAvailableRoute) {
                const routeToNavigate = nextAvailableRoute.child?.find((child: any) => {
                    const hasChildPermission = permissions ? permissions[child.section] : false;
                    return hasChildPermission;
                }) || nextAvailableRoute;
    
                const pathToNavigate = routeToNavigate.path ?? "*";
                navigate(pathToNavigate);
            } else {
                navigate("*");
            }
        }
    };

    return (
        <section style={{
            minWidth: "100%",
            maxWidth: "100%",
            maxHeight: "100vh",
            minHeight: "100vh",
            display: "flex",
            flexGrow: "1",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: colorConfigs.topbar.bg,
            color: "white"
        }}>
            <p style={{ color: "red" }} ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            <h3 style={{
                borderRadius: "5px 5px 0px 0px",
                backgroundColor: "#90A4AE",
                color: "#454545",
                width: "40%",
                marginBlock: "0px",
                padding: "8px 16px"
            }}>
                Sign in to your account
            </h3>
            <form style={{
                width: "40%", height: "60%", backgroundColor: "white",
                borderRadius: "0px 0px 5px 5px",
            }} onSubmit={handleSubmit}>
                <Box sx={{
                    padding: { xs: "2px", md: "16px" },
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "16px"
                }}>

                    <img style={{ width: "200px", height: "100px" }} src="cardjjvanrental.jfif" alt='logo' />

                    <section style={{
                        display: "flex",
                        flexGrow: "1",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <input
                            type="text"
                            id="username"
                            ref={userRef}
                            style={{
                                width: "100%",
                                fontSize: "14px",
                                paddingBlock: "8px",
                            }}
                            autoComplete="off"
                            onChange={(e) => setUser(e.target.value)}
                            value={user}
                            required
                        />

                        <div style={{ marginTop: "16px" }} />

                        <div style={{ position: "relative", width: "100%" }}>
                            <input
                                type={isShowPassword ? "text" : "password"}
                                id="password"
                                style={{
                                    width: "100%",
                                    fontSize: "14px",
                                    paddingBlock: "8px",
                                }}
                                onChange={(e) => setPwd(e.target.value)}
                                value={pwd}
                                required
                            />
                            <span
                                style={{
                                    float: "right",
                                    marginRight: "5px",
                                    position: "absolute",
                                    top: "7px",
                                    right: "0px",
                                    textDecoration: "none",
                                    fontSize: "14px",
                                    color: "#90A4AE",
                                    cursor: "pointer"
                                }}
                                onClick={() => setIsShowPassword(!isShowPassword)}>
                                {isShowPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                            </span>
                        </div>

                    </section>
                </Box>

                <hr style={{ marginBlock: "0px" }} />

                <div style={{
                    padding: "16px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                    <Link to="/forgot-password" style={{
                        color: colorConfigs.topbar.bg,
                        textDecoration: "none"
                    }}>Forgot Password?
                    </Link>
                    <button style={{
                        padding: "8px 10px",
                        backgroundColor: colorConfigs.topbar.bg,
                        color: "white",
                        borderRadius: "4px",
                        cursor: "pointer"
                    }}>
                        {isProcessing ?
                            "Processing..."
                            :
                            "Sign In"
                        }
                    </button>
                </div>

            </form>
        </section>
    )
}

export default Login