import { RouteType } from "./config";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import TuneIcon from '@mui/icons-material/Tune';
import LaunchIcon from '@mui/icons-material/Launch';
import HistoryIcon from '@mui/icons-material/History';
import ViewListIcon from '@mui/icons-material/ViewList';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import CloseIcon from '@mui/icons-material/Close';
import SmsIcon from '@mui/icons-material/Sms';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import SettingsIcon from '@mui/icons-material/Settings';
import NightlightOutlinedIcon from '@mui/icons-material/NightlightOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';
import DiscountIcon from '@mui/icons-material/Discount';
import SecurityIcon from '@mui/icons-material/Security';
import EmailIcon from '@mui/icons-material/Email';
import DescriptionIcon from '@mui/icons-material/Description';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import LayersIcon from '@mui/icons-material/Layers';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';
import HomeRepairServiceOutlinedIcon from '@mui/icons-material/HomeRepairServiceOutlined';
import GroupsIcon from "@mui/icons-material/Groups";
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import ReorderIcon from '@mui/icons-material/Reorder';
import SearchIcon from '@mui/icons-material/Search';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';

import { Admin } from "../pages/settings/admin/Admin";
import { UserManagement } from "../pages/settings/usermanagement/UserManagement";
import { Users } from "../pages/settings/usermanagement/users/Users";
import { UserRoles } from "../pages/settings/usermanagement/UserRoles";
import { QuickLinks } from "../pages/settings/dashboard/quickLinks/QuickLinks";
import { Logs } from "../pages/settings/logs/Logs";
import { AuditLog } from "../pages/settings/logs/AuditLog";
import { EmailsSent } from "../pages/settings/logs/EmailsSent";
import { Suppression } from "../pages/settings/logs/Suppression";
import { SMSSent } from "../pages/settings/logs/SMSSent";
import { ImportHistory } from "../pages/settings/logs/ImportHistory";
import { ExportHistory } from "../pages/settings/logs/ExportHistory";
import { CarRentalPage } from "../pages/settings/carrental/CarRentalPage";
import { AdminDashboard } from "../pages/settings/dashboard/AdminDashboard";
import { AdminCarRental } from "../pages/settings/carrental/AdminCarRental";
import { Seasons } from "../pages/settings/carrental/seasons/Seasons";
import { BlockedDays } from "../pages/settings/carrental/BlockedDays";
import { Rates } from "../pages/settings/carrental/rates/Rates";
import { AddOnCategories } from "../pages/settings/carrental/addOnCategory/AddOnCategories";
import { AdditionalCharges } from "../pages/settings/carrental/additionalCharges/AdditionalCharges";
import { Discounts } from "../pages/settings/carrental/discounts/Discounts";
import { SecurityDeposits } from "../pages/settings/carrental/securityDeposits/SecurityDeposits";
import { EmailTemplates } from "../pages/settings/carrental/emailTemplates/EmailTemplates";
import { RentalAgreement } from "../pages/settings/carrental/rentalAgreement/RentalAgreement";
import { ReturnReceipt } from "../pages/settings/carrental/returnReceipt/ReturnReceipt";
import { AdminFleet } from "../pages/settings/fleet/AdminFleet";
import { FleetSettings } from "../pages/settings/fleet/fleetSettings/FleetSettings";
import { Branches } from "../pages/settings/fleet/branches/Branches";
import { FleetLocations } from "../pages/settings/fleet/fleetLocations/FleetLocations";
import { VehicleClasses } from "../pages/settings/fleet/vehicleClass/VehicleClasses";
import { VehicleTypes } from "../pages/settings/fleet/vehicleTypes/VehicleTypes";
import { VehicleMake } from "../pages/settings/fleet/vehicleMake/VehicleMake";
import { VehicleModel } from "../pages/settings/fleet/vehicleModel/VehicleModel";
import { VehicleEngines } from "../pages/settings/fleet/vehicleEngines/VehicleEngines";
import  Features  from "../pages/settings/fleet/features/Features";
import { AdminContacts } from "../pages/settings/contacts/AdminContacts";
import { PaymentGateways } from "../pages/settings/paymentGateways/PaymentGateways";
import { Settings } from "../pages/settings/paymentGateways/settings/Settings";
import Gateways from "../pages/settings/paymentGateways/gateways/Gateways";
import { Transactions } from "../pages/settings/paymentGateways/transactions/Transactions";
import { CreditCards } from "../pages/settings/paymentGateways/creditCards/CreditCards";
import { AdminChecklist } from "../pages/settings/checklist/AdminChecklist";
import { AdminForms } from "../pages/settings/forms/AdminForms";
import { Accounting } from "../pages/settings/accounting/Accounting";
import { Driver } from "../pages/settings/accounting/Driver";
import { Items } from "../pages/settings/items/Items";
import { Currencies } from "../pages/settings/items/Currencies";
import { Tags } from "../pages/settings/items/Tags";
import { Fields } from "../pages/settings/items/Fields";
import { SavedSearches } from "../pages/settings/items/SavedSearches";
import { Integrations } from "../pages/settings/items/Integrations";
import { ItemEmailTemplates } from "../pages/settings/items/ItemEmailTemplates";
import { Billing } from "../pages/settings/billing/Billing";
import {Maintenance} from "../pages/settings/fleet/maintenance/Maintenance";
import AccessControl from "../pages/settings/usermanagement/users/accessControl/AccessControl";

export const appAdminRoutes: RouteType[] = [
  {
    index: true,
    path: "/admin",
    element: <Admin />,
    state: "admin",
    section: "adminsettings",
    sidebarProps: {
      displayText: "Admin",
      icon: <AdminPanelSettingsIcon sx={{ fontSize: "14px" }} />,
    },
  },
  {
    path: "/admin/usermanagement",
    element: <UserManagement />,
    state: "usermanagement",
    sidebarProps: {
      displayText: "User management",
      icon: <ManageAccountsIcon sx={{ fontSize: "14px" }} />,
    },
    child: [
      {
        path: "/admin/usermanagement/users",
        element: <Users />,
        state: "usermanagement.users",
        section: "settingsusers",
        sidebarProps: {
          displayText: "Users",
          icon: <PeopleAltIcon sx={{ fontSize: "14px" }} />,
        },
      },
      {
        path: "/admin/usermanagement/userroles",
        element: <UserRoles />,
        state: "usermanagement.userroles",
        section: "settingsuserroles",
        sidebarProps: {
          displayText: "User Roles",
          icon: <AssignmentIndIcon sx={{ fontSize: "14px" }} />,
        },
      },
      {
        path: "/admin/usermanagement/accesscontrol",
        element: <AccessControl />,
        state: "usermanagement.accesscontrol",
        section: "settingsaccesscontrol",
        sidebarProps: {
          displayText: "Access control",
          icon: <TuneIcon sx={{ fontSize: "14px" }} />,
        },
      },
    ],
  },
  {
    path: "/admin/dashboard",
    element: <AdminDashboard />,
    state: "admin.dashboard",
    sidebarProps: {
      displayText: "Dashboard",
      icon: <DashboardIcon sx={{ fontSize: "14px" }} />,
    },
    child: [
      {
        path: "/admin/dashboard/quicklinks",
        element: <QuickLinks />,
        state: "admin.dashboard.quicklinks",
        section: "settingsquicklinks",
        sidebarProps: {
          displayText: "Quick Links",
          icon: <LaunchIcon sx={{ fontSize: "14px" }} />,
        },
      },
    ],
  },
  {
    path: "/admin/logs",
    element: <Logs />,
    state: "logs",
    sidebarProps: {
      displayText: "Logs",
      icon: <HistoryIcon sx={{ fontSize: "14px" }} />,
    },
    child: [
      {
        path: "/admin/logs/auditlog",
        element: <AuditLog />,
        state: "logs.auditlog",
        section: "settingsauditlog",
        sidebarProps: {
          displayText: "Audit Log",
          icon: <ViewListIcon sx={{ fontSize: "14px" }} />,
        },
      },
      {
        path: "/admin/logs/emailssent",
        element: <EmailsSent />,
        state: "logs.emailssent",
        section: "settingsemailssent",
        sidebarProps: {
          displayText: "Emails Sent",
          icon: <MarkEmailReadIcon sx={{ fontSize: "14px" }} />,
        },
      },
      {
        path: "/admin/logs/suppression",
        element: <Suppression />,
        state: "logs.suppression",
        section: "settingssuppression",
        sidebarProps: {
          displayText: "Suppression",
          icon: <CloseIcon sx={{ fontSize: "14px" }} />,
        },
      },
      {
        path: "/admin/logs/smssent",
        element: <SMSSent />,
        state: "logs.smssent",
        section: "settingssmssent",
        sidebarProps: {
          displayText: "SMS Sent",
          icon: <SmsIcon sx={{ fontSize: "14px" }} />,
        },
      },
      {
        path: "/admin/logs/importhistory",
        element: <ImportHistory />,
        state: "logs.importhistory",
        section: "settingsimporthistory",
        sidebarProps: {
          displayText: "Import History",
          icon: <CloudUploadIcon sx={{ fontSize: "14px" }} />,
        },
      },
      {
        path: "/admin/logs/exporthistory",
        element: <ExportHistory />,
        state: "logs.exporthistory",
        section: "settingsexporthistory",
        sidebarProps: {
          displayText: "Export History",
          icon: <CloudDownloadIcon sx={{ fontSize: "14px" }} />,
        },
      },
    ],
  },
  {
    path: "/admin/carrental",
    element: <AdminCarRental />,
    state: "carrental",
    sidebarProps: {
      displayText: "Car Rental",
      icon: <EventAvailableIcon sx={{ fontSize: "14px" }} />,
    },
    child: [
      {
        path: "/admin/carrental/carrental",
        element: <CarRentalPage />,
        state: "carrental.carrental",
        section: "settingscarrental",
        sidebarProps: {
          displayText: "Car Rental",
          icon: <SettingsIcon sx={{ fontSize: "14px" }} />,
        },
      },
      {
        path: "/admin/carrental/seasons",
        element: <Seasons />,
        state: "carrental.seasons",
        section: "settingsseasons",
        sidebarProps: {
          displayText: "Seasons",
          icon: <NightlightOutlinedIcon sx={{ fontSize: "14px" }} />,
        },
      },
      {
        path: "/admin/carrental/blockeddays",
        element: <BlockedDays />,
        state: "carrental.blockeddays",
        section: "settingsblockeddays",
        sidebarProps: {
          displayText: "Blocked Days",
          icon: <BlockOutlinedIcon sx={{ fontSize: "14px" }} />,
        },
      },
      {
        path: "/admin/carrental/rates",
        element: <Rates />,
        state: "carrental.rates",
        section: "settingsrates",
        sidebarProps: {
          displayText: "Rates",
          icon: <CreditCardIcon sx={{ fontSize: "14px" }} />,
        },
      },
      {
        path: "/admin/carrental/addoncategories",
        element: <AddOnCategories />,
        state: "carrental.addoncategories",
        section: "settingsaddoncategories",
        sidebarProps: {
          displayText: "Add-On-Categories",
          icon: <AddBusinessIcon sx={{ fontSize: "14px" }} />,
        },
      },
      {
        path: "/admin/carrental/additionalcharges",
        element: <AdditionalCharges />,
        state: "carrental.additionalcharges",
        section: "settingsadditionalcharges",
        sidebarProps: {
          displayText: "Additional Charges",
          icon: <AddShoppingCartOutlinedIcon sx={{ fontSize: "14px" }} />,
        },
      },
      {
        path: "/admin/carrental/discounts",
        element: <Discounts />,
        state: "carrental.discounts",
        section: "settingsdiscounts",
        sidebarProps: {
          displayText: "Discounts",
          icon: <DiscountIcon sx={{ fontSize: "14px" }} />,
        },
      },
      {
        path: "/admin/carrental/securitydeposits",
        element: <SecurityDeposits />,
        state: "carrental.securitydeposits",
        section: "settingssecuritydeposits",
        sidebarProps: {
          displayText: "Security Deposits",
          icon: <SecurityIcon sx={{ fontSize: "14px" }} />,
        },
      },
      {
        path: "/admin/carrental/emailtemplates",
        element: <EmailTemplates />,
        state: "carrental.emailtemplates",
        section: "settingsemailtemplates",
        sidebarProps: {
          displayText: "Email Templates",
          icon: <EmailIcon sx={{ fontSize: "14px" }} />,
        },
      },
      {
        path: "/admin/carrental/rentalagreement",
        element: <RentalAgreement />,
        state: "carrental.rentalagreement",
        section: "settingsrentalagreement",
        sidebarProps: {
          displayText: "Rental Agreement",
          icon: <DescriptionIcon sx={{ fontSize: "14px" }} />,
        },
      },
      {
        path: "/admin/carrental/returnreceipt",
        element: <ReturnReceipt />,
        state: "carrental.returnreceipt",
        section: "settingsreturnreceipt",
        sidebarProps: {
          displayText: "Return Receipt",
          icon: <DescriptionIcon sx={{ fontSize: "14px" }} />,
        },
      },
    ],
  },
  {
    path: "/admin/fleet",
    element: <AdminFleet />,
    state: "fleet",
    sidebarProps: {
      displayText: "Fleet",
      icon: <DirectionsCarIcon sx={{ fontSize: "14px" }} />,
    },
    child: [
      {
        path: "/admin/fleet/fleet",
        element: <FleetSettings />,
        state: "fleet.fleet",
        section: "settingsfleet",
        sidebarProps: {
          displayText: "Fleet",
          icon: <SettingsIcon sx={{ fontSize: "14px" }} />,
        },
      },
      {
        path: "/admin/fleet/branches",
        element: <Branches />,
        state: "fleet.branches",
        section: "settingsbranches",
        sidebarProps: {
          displayText: "Branches",
          icon: <LayersIcon sx={{ fontSize: "14px" }} />,
        },
      },
      {
        path: "/admin/fleet/fleetlocations",
        element: <FleetLocations />,
        state: "fleet.fleetlocations",
        section: "settingsfleetlocations",
        sidebarProps: {
          displayText: "Fleet Locations",
          icon: <LocationCityIcon sx={{ fontSize: "14px" }} />,
        },
      },
      {
        path: "/admin/fleet/vehicleclasses",
        element: <VehicleClasses />,
        state: "fleet.vehicleclasses",
        section: "settingsvehicleclasses",
        sidebarProps: {
          displayText: "Vehicle Classes",
          icon: <DiscountIcon sx={{ fontSize: "14px" }} />,
        },
      },
      {
        path: "/admin/fleet/vehicletypes",
        element: <VehicleTypes />,
        state: "fleet.vehicletypes",
        section: "settingsvehicletypes",
        sidebarProps: {
          displayText: "Vehicle Types",
          icon: <LocalOfferIcon sx={{ fontSize: "14px" }} />,
        },
      },
      {
        path: "/admin/fleet/vehiclemake",
        element: <VehicleMake />,
        state: "fleet.vehiclemake",
        section: "settingsvehiclemake",
        sidebarProps: {
          displayText: "Vehicle Make",
          icon: <LocalOfferIcon sx={{ fontSize: "14px" }} />,
        },
      },
      {
        path: "/admin/fleet/vehiclemodel",
        element: <VehicleModel />,
        state: "fleet.vehiclemodel",
        section: "settingsvehiclemodel",
        sidebarProps: {
          displayText: "Vehicle Model",
          icon: <LocalOfferIcon sx={{ fontSize: "14px" }} />,
        },
      },
      {
        path: "/admin/fleet/vehicleengines",
        element: <VehicleEngines />,
        state: "fleet.vehicleengines",
        section: "settingsvehicleengines",
        sidebarProps: {
          displayText: "Vehicle Engines",
          icon: <LocalOfferIcon sx={{ fontSize: "14px" }} />,
        },
      },
      {
        path: "/admin/fleet/features",
        element: <Features />,
        state: "fleet.features",
        section: "settingsfeatures",
        sidebarProps: {
          displayText: "Features",
          icon: <StorageOutlinedIcon sx={{ fontSize: "14px" }} />,
        },
      },
      {
        path: "/admin/fleet/maintenance",
        element: <Maintenance />,
        state: "fleet.maintenance",
        section: "settingsmaintenance",
        sidebarProps: {
          displayText: "Maintenance",
          icon: <HomeRepairServiceOutlinedIcon sx={{ fontSize: "14px" }} />,
        },
      },
    ],
  },
  {
    path: "/admin/contacts",
    element: <AdminContacts />,
    state: "contacts",
    section: "settingscontacts",
    sidebarProps: {
      displayText: "Contacts",
      icon: <GroupsIcon sx={{ fontSize: "14px" }} />,
    },
  },
  {
    path: "/admin/paymentgateways",
    element: <PaymentGateways />,
    state: "paymentgateways",
    sidebarProps: {
      displayText: "Payment Gateways",
      icon: <PaidOutlinedIcon sx={{ fontSize: "14px" }} />,
    },
    child: [
      {
        path: "/admin/paymentgateways/settings",
        element: <Settings />,
        state: "paymentgateways.settings",
        section: "settingsforpaymentgateways",
        sidebarProps: {
          displayText: "Settings",
          icon: <SettingsIcon sx={{ fontSize: "14px" }} />,
        },
      },
      {
        path: "/admin/paymentgateways/gateways",
        element: <Gateways />,
        state: "paymentgateways.gateways",
        section: "settingsgateways",
        sidebarProps: {
          displayText: "Gateways",
          icon: <PaidOutlinedIcon sx={{ fontSize: "14px" }} />,
        },
      },
      {
        path: "/admin/paymentgateways/transactions",
        element: <Transactions />,
        state: "paymentgateways.transactions",
        section: "settingstransactions",
        sidebarProps: {
          displayText: "Transactions",
          icon: <PaymentsOutlinedIcon sx={{ fontSize: "14px" }} />,
        },
      },
      {
        path: "/admin/paymentgateways/creditcards",
        element: <CreditCards />,
        state: "paymentgateways.creditcards",
        section: "settingscreditcards",
        sidebarProps: {
          displayText: "Credit Cards",
          icon: <CreditCardOutlinedIcon sx={{ fontSize: "14px" }} />,
        },
      },
    ],
  },
  {
    path: "/admin/checklist",
    element: <AdminChecklist />,
    state: "checklist",
    section: "settingschecklist",
    sidebarProps: {
      displayText: "Checklist",
      icon: <CheckBoxIcon sx={{ fontSize: "14px" }} />,
    },
  },
  {
    path: "/admin/forms",
    element: <AdminForms />,
    state: "forms",
    section: "settingsadminforms",
    sidebarProps: {
      displayText: "Forms",
      icon: <CalendarViewMonthIcon sx={{ fontSize: "14px" }} />,
    },
  },
  {
    path: "/admin/accounting",
    element: <Accounting />,
    state: "accounting",
    sidebarProps: {
      displayText: "Accounting",
      icon: <AccountBalanceIcon sx={{ fontSize: "14px" }} />,
    },
    child: [
      {
        path: "/admin/accounting/driver",
        element: <Driver />,
        state: "accounting.driver",
        section: "settingsdriver",
        sidebarProps: {
          displayText: "Driver",
          icon: <StorageOutlinedIcon sx={{ fontSize: "14px" }} />,
        },
      },
    ],
  },
  {
    path: "/admin/items",
    element: <Items />,
    state: "items",
    sidebarProps: {
      displayText: "Items",
      icon: <StorageOutlinedIcon sx={{ fontSize: "14px" }} />,
    },
    child: [
      {
        path: "/admin/items/currencies",
        element: <Currencies />,
        state: "items.currencies",
        section: "settingscurrencies",
        sidebarProps: {
          displayText: "Currencies",
          icon: <CurrencyExchangeIcon sx={{ fontSize: "14px" }} />,
        },
      },
      {
        path: "/admin/items/tags",
        element: <Tags />,
        state: "items.tags",
        section: "settingstags",
        sidebarProps: {
          displayText: "Tags",
          icon: <LocalOfferIcon sx={{ fontSize: "14px" }} />,
        },
      },
      {
        path: "/admin/items/fields",
        element: <Fields />,
        state: "items.fields",
        section: "settingsfields",
        sidebarProps: {
          displayText: "Fields",
          icon: <ReorderIcon sx={{ fontSize: "14px" }} />,
        },
      },
      {
        path: "/admin/items/savedsearches",
        element: <SavedSearches />,
        state: "items.savedsearches",
        section: "settingssavedsearches",
        sidebarProps: {
          displayText: "Saved Searches",
          icon: <SearchIcon sx={{ fontSize: "14px" }} />,
        },
      },
      {
        path: "/admin/items/integrations",
        element: <Integrations />,
        state: "items.integrations",
        section: "settingsintegrations",
        sidebarProps: {
          displayText: "Integrations",
          icon: <ShuffleIcon sx={{ fontSize: "14px" }} />,
        },
      },
      {
        path: "/admin/items/emailtemplates",
        element: <ItemEmailTemplates />,
        state: "items.emailtemplates",
        section: "settingsitememailtemplates",
        sidebarProps: {
          displayText: "Email Templates",
          icon: <EmailIcon sx={{ fontSize: "14px" }} />,
        },
      },
    ],
  },
  {
    path: "/admin/billing",
    element: <Billing />,
    state: "billing",
    section: "settingsbilling",
    sidebarProps: {
      displayText: "Billing",
      icon: <ReceiptLongOutlinedIcon sx={{ fontSize: "14px" }} />,
    },
  }
];

export default appAdminRoutes;
