import React, { useEffect, useState } from 'react';
import CalendarHeader from './CalendarHeader';
import Timeliner from './Timeliner';
import baseURL from '../../../configs/api';
import moment from 'moment';
import { formatInDateOnly, formatTimeWithCustomAMPM, Views } from './utlis';
import { useNavigate } from 'react-router-dom';


const Calendar = () => {
  const navigate = useNavigate();
  const [currentTimeLineView, setCurrentTimeLineView] = useState<Views>(Views.TimeSlot3MONTH);
  const [timeLineHeight, setTimeLineHeight] = useState<number>(367);
  const [onPrint, setOnPrint] = useState<(() => void)>(() => { });
  const [resourcesDataIsLoaded, setResourcesDataIsLoaded] = useState<boolean>(false);

  const [vehilceClassData, setVehilceClassData] = useState<any[]>([]);
  const [vehicleClassDataIsDataLoaded, setVehicleClassDataIsDataLoaded] = useState<boolean>(false);

  const [vehilceData, setVehilceData] = useState<any[]>([]);
  const [vehicleDataIsDataLoaded, setVehicleDataIsDataLoaded] = useState<boolean>(false);

  const [reservationItem, setReservationItem] = useState<any[]>([]);
  const [reservationItemsIsLoaded, setReservationItemIsLoaded] = useState<boolean>(false);

  const statusColor: any[] = [
    { status: "open", color: "#f0ad4e" },
    { status: "rental", color: "#66BB6A" },
    { status: "completed", color: "#253455" },
    { status: "pending", color: "#808080" },
  ];


  useEffect(() => {
    fetch(`${baseURL}vehicleClass`)
      .then((res) => res.json())
      .then((data) => {
        setVehilceClassData(() =>
          data.map((item: any) => {
            return {
              id: item.id,
              title: item.name,
            };
          })
        );

        setVehicleClassDataIsDataLoaded(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    fetch(`${baseURL}vehicles`)
      .then((res) => res.json())
      .then((data) => {
        setVehilceData(
          data.map((item: any) => {
            return {
              id: item.id,
              title: item.name,
              groupId: item.vehicleClassId
            };
          })
        );

        setVehicleDataIsDataLoaded(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setReservationItemIsLoaded(false);
    let startDate = moment();
    let endDate = moment();

    switch (currentTimeLineView) {
      case Views.TimeSlotToday:
        startDate = startDate.clone().startOf('day');
        endDate = endDate.clone().endOf('day');
        break;
      case Views.TimeSlotWEEK:
        startDate = startDate.clone().startOf('week');
        endDate = endDate.clone().endOf('week');
        break;
      case Views.TimeSlotMONTH:
        startDate = startDate.clone().startOf('month');
        endDate = endDate.clone().endOf('month');
        break;
      case Views.TimeSlot3MONTH:
        startDate = startDate.clone().startOf('month');
        endDate = endDate.clone().add(2, 'month').endOf('month');
        break;
      case Views.TimeSlot6MONTH:
        startDate = startDate.clone().startOf('month');
        endDate = endDate.clone().add(5, 'month').endOf('month');
        break;
      case Views.TimeSlotYear:
        startDate = startDate.clone().startOf('year');
        endDate = endDate.clone().endOf('year');
        break;
      default:
        startDate = startDate.clone().startOf('month');
        endDate = endDate.clone().add(2, 'month').endOf('month');
        break;
    }

    fetch(`${baseURL}reservation/GetReservationsForCalendarWithDateRange/${formatInDateOnly(startDate)},${formatInDateOnly(endDate)}`)
      .then((res) => res.json())
      .then((data) => {
        setReservationItem(
          data.map((item: any) => {
            const customerName = item?.customerName || "";
            const title = `${formatTimeWithCustomAMPM(moment(new Date(item.startDate)))} - ${formatTimeWithCustomAMPM(moment(new Date(item.endDate)))} ${item.bookingNumber} - ${customerName}`;

            return {
              id: item.id,
              resourceId: item.status === "Open" ? item.vehicleClassId : item.vehicleId,
              title: title,
              start: item.startDate,
              end: item.endDate,
              bgColor: item.status?.toLowerCase() === "completed" && item.outstandingBalance !== 0
                ? "#F57C00"
                : statusColor.find((s: any) => s.status === item.status?.toLowerCase())?.color,
              borderColor: item.status?.toLowerCase() === "completed" && item.outstandingBalance !== 0
                ? "#F57C00"
                : statusColor.find((s: any) => s.status === item.status?.toLowerCase())?.color
            };
          })
        );

        setReservationItemIsLoaded(true);
      })
      .catch((error) => {
        console.log(error);
        setReservationItem([]);
        setReservationItemIsLoaded(true);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTimeLineView]);

  const handleEventClick = (reservationId: number) => navigate("/carrental/reservations/editreservation", { state: reservationId });


  return (
    <div
      style={{
        height: `calc(100vh - ${timeLineHeight - 40}px)`
      }}
    >
      <div className="calendar"
        style={{
          fontSize: "14px",
          lineHeight: 1.2,
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          WebkitUserSelect: "none" /* Safari */,
          msUserSelect: "none" /* IE 10 and IE 11 */,
          userSelect: "none" /* Standard syntax */,
          touchAction: "none",
          boxSizing: "border-box"
        }}
      >
        <CalendarHeader
          setTimeLineHeight={setTimeLineHeight}
          time={currentTimeLineView}
          setTime={(time) => setCurrentTimeLineView(time)}
          setResourcesDataIsLoaded={setResourcesDataIsLoaded}
          onPrint={onPrint}
        />

        <Timeliner
          timeLineHeight={timeLineHeight}
          groups={vehilceClassData}
          groupsIsLoaded={vehicleClassDataIsDataLoaded}
          resources={vehilceData}
          resourcesIsLoaded={vehicleDataIsDataLoaded}
          events={reservationItem}
          eventsIsLoaded={reservationItemsIsLoaded}
          resourcesDataIsLoaded={resourcesDataIsLoaded}
          setResourcesDataIsLoaded={setResourcesDataIsLoaded}
          views={currentTimeLineView}
          onItemClick={handleEventClick}
          print={setOnPrint}
        />
      </div>
    </div>
  );
};

export default Calendar;