import { Stack, Button, Drawer, List, styled } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import SlidebarItemWithOutCollapse from "./SlidebarItemWithOutCollapse";
import SidebarItemCollapse from "./SidebarItemCollapse";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useNavigate } from "react-router-dom";
import appAdminRoutes from "../../routes/appAdminRoutes";
import useAccessControl from "../hooks/useAccessControl";
import Cookies from "js-cookie";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  marginTop: "30px",
  padding: theme.spacing(0, 1),
  backgroundColor: colorConfigs.sidebar.bg,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AdminSidebar = () => {
  const navigate = useNavigate();
  const { permissions }: any = useAccessControl();
  const role = Cookies.get("role") || '';

  return (
    <Drawer
      variant="permanent"
      open={true}
      sx={{
        width: sizeConfigs.sidebar.width,
        flexShrink: 0,
        borderRight: "1px solid #eeeeee"
      }}
    >
      <DrawerHeader />

      <Stack
        sx={{
          width: sizeConfigs.sidebar.width,
        }}
      >
        <Button
          sx={{
            justifyContent: "start",
            textTransform: "none",
            color: colorConfigs.sidebar.color,
            backgroundColor: colorConfigs.sidebar.bg,
            "&: hover": {
              textDecoration: "underline",
            },
          }}
          onClick={() => navigate(-1)}
        >
          <ArrowBackOutlinedIcon
            sx={{ fontSize: "16px", marginRight: "3px", textAlign: "start" }}
          />
          Go Back
        </Button>
      </Stack>

      <List
        disablePadding
        sx={{
          backgroundColor: colorConfigs.sidebar.bg,
          color: colorConfigs.sidebar.color,
          height: "210%"
        }}
      >
        {appAdminRoutes.map((route, index) => {
          if (role.toLowerCase() === "admin") {
            return route.sidebarProps && route.child ? (
                <SidebarItemCollapse item={route} key={index} />
            ) : (
                <SlidebarItemWithOutCollapse item={route} key={index} />
            );
        }
          const hasAccessSection = route?.section ? permissions[route.section] : route.child ? route.child?.filter((child: any) => permissions[child.section] === true).length > 0 ? true : false : false;

          if (role === "admin" || hasAccessSection) {
            return route.sidebarProps && route.child ? (
              <SidebarItemCollapse item={route} key={index} />
            ) : (
              <SlidebarItemWithOutCollapse item={route} key={index} />
            );
          }

          return null;
        })}
      </List>
    </Drawer>
  );
};

export default AdminSidebar;
