import { useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Toolbar,
  Typography,
} from "@mui/material";
import Select from "react-select";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import DiscountIcon from "@mui/icons-material/Discount";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import LocalSeeIcon from "@mui/icons-material/LocalSee";
import axios from "axios";

import { useNavigate } from "react-router-dom";
import baseURL from "../../../../configs/api";
import colorConfigs from "../../../../configs/colorConfigs";

import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import React from "react";
import PleaseWaitPopup from "../../../PleaseWaitPopup";

export default function AddVehicleClass() {
  const navigate = useNavigate();
  const [branch, setBranch] = useState([]);
  const [name, setName] = useState<string>("");
  const [branchId, setBranchId] = useState<string>("");
  const [availableOnWebsite, setAvailableOnWebsite] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);
  const [order, setOrder] = useState<string>("");
  const [calenderOrder, setCalenderOrder] = useState<string>("");
  const [allowedOverbookingQuantity, setAllowedOverbookingQuantity] =
    useState<string>("");
  const [overbookingBackupClassId, setOverbookingBackupClassId] =
    useState<string>("");
  const [minimumRentalAllowedAge, setMinimumRentalAllowedAge] =
    useState<string>("");
  const [maxRentalAllowedAge, setMaxRentalAllowedAge] = useState<string>("");
  const [tankSize, setTankSize] = useState<string>("");
  const [fuelChargeperOneByEight, setFuelChargeperOneByEight] =
    useState<string>("");
  const [allowedRoles, setAllowedRoles] = useState<string>("");
  const [allowedLocations, setAllowedLocations] = useState<string>("");
  const [restrictedLocations, setRestrictedLocations] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [spareQuantity, setSpareQuantity] = useState<string>("");
  const [distancelimit, setDistancelimit] = useState<string>("");
  const [distanceLimitperDay, setDistanceLimitperDay] = useState<string>("");
  const [distanceLimitperWeek, setDistanceLimitperWeek] = useState<string>("");
  const [distanceLimitperMonth, setDistanceLimitperMonth] =
    useState<string>("");
  const [
    additionalChargeforExceededDistance,
    setAdditionalChargeforExceededDistance,
  ] = useState<string>("");
  const [sipp, setSipp] = useState<string>("");
  const [driverYearsExperience, setDriverYearsExperience] =
    useState<string>("");
  const [enableFuelLevelTracking, seteEnableFuelLevelTracking] =
    useState<boolean>(false);
  const [enableOdometerTracking, setEnableOdometerTracking] =
    useState<boolean>(false);
  const [similarVehicleMakeId, setSimilarVehicleMakeId] = useState<string>("");
  const [similarVehicleModelId, setSimilarVehicleModelId] =
    useState<string>("");
  const [features, setFeatures] = useState<any>();
  const [featuresOptions, setFeaturesOptions] = useState<any[]>([]);
  const [label, setLabel] = useState<string>("");
  const [shortDiscription, setShortDiscription] = useState<string>("");
  const [discription, setDiscription] = useState<string>("");

  const [recommended, setRecommended] = useState<boolean>(false);
  const [limitedAvailability, setLimitedAvailability] =
    useState<boolean>(false);
  const [
    percentageforLimitedAvailability,
    setPercentageForLimitedAvailability,
  ] = useState<string>("");

  const [isWebsite, setIsWebsite] = useState<boolean>(true);

  const [showWebcam, setShowWebcam] = useState<boolean>(false);
  const [filePath, setFilePath] = useState<string>("");
  const [file, setFile] = useState<FileList | null>(null);
  const [imagePopup, setImagePopup] = useState<boolean>(false);
  const [pleaseWaitPopup, setPleaseWaitPopup] = useState<boolean>(false);

  useEffect(() => {
    axios
      .get(`${baseURL}branch`)
      .then((response) => {
        setBranch(
          response.data.map((item: any) => {
            return { value: item.id, label: item.branchName };
          })
        );

        setBranchId(response.data[0].id);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${baseURL}Feature`)
      .then((response) => {
        setFeaturesOptions(() =>
          response.data.map((item: any) => {
            return {
              value: item.id,
              label: item.label
            };
          })
        );

      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!pleaseWaitPopup) {
      setPleaseWaitPopup(true);
      if (filePath === "" && file === null) {
        alert("Please add an image");
        setPleaseWaitPopup(false);
      } else {
        const formData = new FormData();
        if (file !== null) {
          formData.append("ImageFile", file[0]);

          axios
            .post(`${baseURL}vehicleClassFile/upload`, formData, {
              headers: { "Content-Type": "multipart/form-data" },
              withCredentials: true,
            })
            .then((response) => saveVehicleClass(response.data))
            .catch(error => {
              console.error("Error:", error);
              setPleaseWaitPopup(false);
            });
        } else {
          saveVehicleClass(filePath);
        }
      }
    }
  };

  const saveVehicleClass = (fileName: string) => {
    axios
      .post(
        `${baseURL}vehicleClass`,
        JSON.stringify({
          name,
          branchId,
          imagePath: fileName,
          availableOnWebsite,
          active,
          recommended,
          order,
          calenderOrder,
          allowedOverbookingQuantity,
          overbookingBackupClassId,
          minimumRentalAllowedAge,
          maxRentalAllowedAge,
          tankSize,
          limitedAvailability,
          percentageforLimitedAvailability,
          fuelChargeperOneByEight,
          allowedRoles,
          allowedLocations,
          restrictedLocations,
          startDate: startDate + "T00:00:00",
          spareQuantity,
          distancelimit,
          distanceLimitperDay,
          distanceLimitperWeek,
          distanceLimitperMonth,
          additionalChargeforExceededDistance,
          sipp,
          driverYearsExperience,
          enableFuelLevelTracking,
          enableOdometerTracking,
          similarVehicleMakeId,
          similarVehicleModelId,
          features: features?.map((item: any) => item.value).join(","),
          label,
          shortDiscription,
          discription,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then(() => {
        setPleaseWaitPopup(false);
        navigate(-1);
      })
      .catch(error => {
        setPleaseWaitPopup(false);
        console.error("Error:", error);
      });
  }

  const webRef = useRef(null);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const onFileChangeCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
    /*Selected files data can be collected here.*/
    const selectedFiles = e.target.files as FileList;

    if (selectedFiles.length !== 0) {
      setFilePath("");
      setFile(selectedFiles);
    }
  };

  const [editorStateShort, setEditorStateShort] = useState(() =>
    EditorState.createEmpty()
  );
  const [editorStateDescription, setEditorStateDescription] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    setShortDiscription(
      draftToHtml(convertToRaw(editorStateShort.getCurrentContent()))
    );
  }, [editorStateShort]);

  useEffect(() => {
    setDiscription(
      draftToHtml(convertToRaw(editorStateDescription.getCurrentContent()))
    );
  }, [editorStateDescription]);

  const handleDeleteImage = () => {
    if (window.confirm("Are you sure you want to delete this Image?") === true) {
      if (inputFileRef.current) {
        inputFileRef.current.value = '';
      }
      setFile(null);
      setFilePath("");
    }
  }

  return (
    <>
      <Toolbar sx={{ width: "100%", justifyContent: "space-between" }}>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <DiscountIcon sx={{ fontSize: "24px", marginRight: "5px" }} />
          <Typography variant="body1" sx={{ fontSize: "20px" }}>
            Vehicle Classes / Add Vehicle Class
          </Typography>
        </Box>
      </Toolbar>

      <hr />

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          padding: "24px",
        }}
      >
        <div
          style={{
            width: "75%",
            marginRight: "3%",
          }}
        >
          <div
            style={{
              width: "100%",
              marginRight: "3%",
            }}
          >
            <h5
              style={{
                paddingBottom: "0px",
                marginBottom: "0px",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              General
            </h5>
            <hr />

            <form onSubmit={(e) => handleSubmit(e)}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    marginRight: "3%",
                  }}
                >
                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="name"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Name
                      <br />
                      <input
                        type="text"
                        name="name"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="image"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Images
                      <br />
                      {(file || filePath) && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          {file && (
                            <img
                              src={URL.createObjectURL(file[0])}
                              width={40}
                              height={40}
                              alt="vehicle"
                              onClick={() => setImagePopup(true)}
                            />
                          )}

                          {filePath && (
                            <img
                              src={filePath}
                              width={40}
                              height={40}
                              alt="vehicle"
                              onClick={() => setImagePopup(true)}
                            />
                          )}

                          <RemoveCircleIcon
                            sx={{
                              fontSize: "14px",
                              color: colorConfigs.topbar.bg,
                              fontWeight: "bold",
                              cursor: "pointer",
                              "&: hover": {
                                color: "red",
                              },
                            }}
                            onClick={handleDeleteImage}
                          />
                        </div>
                      )}
                      <input
                        type="file"
                        accept="image/*"
                        ref={inputFileRef}
                        onChangeCapture={onFileChangeCapture}
                        style={{ display: "none" }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "8px",
                          marginTop: "8px",
                        }}
                      >
                        <p
                          style={{
                            border: "1px dashed black",
                            borderRadius: "3px",
                            padding: "3px 5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "12px",
                            backgroundColor: "white",
                            cursor: "pointer"
                          }}
                          onClick={(e) => {
                            if (inputFileRef.current !== null) {
                              inputFileRef.current.click();
                            }
                          }}
                        >
                          {" "}
                          <AddIcon
                            style={{
                              fontSize: "14px",
                              color: "black",
                              fontWeight: "bold",
                            }}
                          />
                          Add Image
                        </p>

                        <p
                          style={{
                            border: "1px dashed black",
                            borderRadius: "3px",
                            padding: "3px 5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "12px",
                            backgroundColor: "white",
                            cursor: "pointer"
                          }}
                          onClick={() => setShowWebcam(true)}>
                          {" "}
                          <LocalSeeIcon
                            style={{
                              fontSize: "14px",
                              color: "black",
                              fontWeight: "bold",
                            }}
                          />
                        </p>
                      </div>
                    </label>
                  </div>
                </div>

                <div
                  style={{
                    width: "50%",
                  }}
                >
                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor=" branch"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Branch
                      <br />
                      <select
                        name="branch"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={branchId}
                        onChange={(e) => setBranchId(e.target.value)}
                      >
                        {branch.map((item: any) => {
                          return (
                            <option key={item.value} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })}
                      </select>
                    </label>
                  </div>
                </div>
              </div>

              <h5
                style={{
                  paddingBottom: "0px",
                  marginBottom: "0px",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                Rental Process
              </h5>
              <hr />

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    marginRight: "3%",
                  }}
                >
                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="availableOnWebsite"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Available on Website?
                      <br />
                      <div>
                        <label style={{ fontSize: "12px" }}>
                          <input
                            type="radio"
                            value="Yes"
                            name="availableOnWebsite"
                            style={{ fontSize: "12px" }}
                            checked={availableOnWebsite ? true : false}
                            onChange={(e) =>
                              setAvailableOnWebsite(
                                e.target.value === "Yes" ? true : false
                              )
                            }
                          />{" "}
                          Yes
                        </label>
                        <label style={{ fontSize: "12px" }}>
                          <input
                            type="radio"
                            value="No"
                            name="availableOnWebsite"
                            style={{ fontSize: "12px" }}
                            checked={availableOnWebsite ? false : true}
                            onChange={(e) =>
                              setAvailableOnWebsite(
                                e.target.value === "Yes" ? true : false
                              )
                            }
                          />{" "}
                          No
                        </label>
                      </div>
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="recommended"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Recommended?
                      <br />
                      <div>
                        <label style={{ fontSize: "12px" }}>
                          <input
                            type="radio"
                            value="Yes"
                            name="recommended"
                            style={{ fontSize: "12px" }}
                            checked={recommended ? true : false}
                            onChange={(e) =>
                              setRecommended(
                                e.target.value === "Yes" ? true : false
                              )
                            }
                          />{" "}
                          Yes
                        </label>
                        <label style={{ fontSize: "12px" }}>
                          <input
                            type="radio"
                            value="No"
                            name="recommended"
                            style={{ fontSize: "12px" }}
                            checked={recommended ? false : true}
                            onChange={(e) =>
                              setRecommended(
                                e.target.value === "Yes" ? true : false
                              )
                            }
                          />{" "}
                          No
                        </label>
                      </div>
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="order"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Order
                      <br />
                      <input
                        type="number"
                        name="order"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={order}
                        onChange={(e) => setOrder(e.target.value)}
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="allowedOverbookingQuantity"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Allowed Overbooking Quantity
                      <br />
                      <input
                        type="number"
                        name="allowedOverbookingQuantity"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={allowedOverbookingQuantity}
                        onChange={(e) =>
                          setAllowedOverbookingQuantity(e.target.value)
                        }
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="minimumRentalAllowedAge"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Minimum Rental Allowed Age
                      <br />
                      <input
                        type="number"
                        name="minimumRentalAllowedAge"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={minimumRentalAllowedAge}
                        onChange={(e) =>
                          setMinimumRentalAllowedAge(e.target.value)
                        }
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="tankSize"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Tank Size
                      <br />
                      <input
                        type="number"
                        name="tankSize"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={tankSize}
                        onChange={(e) => setTankSize(e.target.value)}
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="limitedAvailability"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Limited Availability
                      <br />
                      <div>
                        <label style={{ fontSize: "12px" }}>
                          <input
                            type="radio"
                            value="Yes"
                            name="limitedAvailability"
                            style={{ fontSize: "12px" }}
                            checked={limitedAvailability ? true : false}
                            onChange={(e) =>
                              setLimitedAvailability(
                                e.target.value === "Yes" ? true : false
                              )
                            }
                          />{" "}
                          Yes
                        </label>
                        <label style={{ fontSize: "12px" }}>
                          <input
                            type="radio"
                            value="No"
                            name="limitedAvailability"
                            style={{ fontSize: "12px" }}
                            checked={limitedAvailability ? false : true}
                            onChange={(e) =>
                              setLimitedAvailability(
                                e.target.value === "Yes" ? true : false
                              )
                            }
                          />{" "}
                          No
                        </label>
                      </div>
                    </label>
                  </div>
                </div>

                <div
                  style={{
                    width: "50%",
                  }}
                >
                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="active"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Active?
                      <br />
                      <div>
                        <label style={{ fontSize: "12px" }}>
                          <input
                            type="radio"
                            value="Yes"
                            name="active"
                            style={{ fontSize: "12px" }}
                            checked={active ? true : false}
                            onChange={(e) =>
                              setActive(e.target.value === "Yes" ? true : false)
                            }
                          />{" "}
                          Yes
                        </label>
                        <label style={{ fontSize: "12px" }}>
                          <input
                            type="radio"
                            value="No"
                            name="active"
                            style={{ fontSize: "12px" }}
                            checked={active ? false : true}
                            onChange={(e) =>
                              setActive(e.target.value === "Yes" ? true : false)
                            }
                          />{" "}
                          No
                        </label>
                      </div>
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="calenderOrder"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Calendar Order
                      <br />
                      <input
                        type="number"
                        name="calenderOrder"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={calenderOrder}
                        onChange={(e) => setCalenderOrder(e.target.value)}
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="overbookingBackupClassId"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Overbooking Backup Class
                      <br />
                      <input
                        type="number"
                        name="overbookingBackupClassId"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={overbookingBackupClassId}
                        onChange={(e) =>
                          setOverbookingBackupClassId(e.target.value)
                        }
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="maxRentalAllowedAge"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Maximum Rental Allowed Age
                      <br />
                      <input
                        type="number"
                        name="maxRentalAllowedAge"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={maxRentalAllowedAge}
                        onChange={(e) => setMaxRentalAllowedAge(e.target.value)}
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="fuelChargeperOneByEight"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Fuel Charge per 1/8
                      <br />
                      <input
                        type="number"
                        name="fuelChargeperOneByEight"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={fuelChargeperOneByEight}
                        onChange={(e) =>
                          setFuelChargeperOneByEight(e.target.value)
                        }
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="percentageForLimitedAvailability"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Percentage for Limited Availability
                      <br />
                      <input
                        type="number"
                        name="percentageForLimitedAvailability"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={percentageforLimitedAvailability}
                        onChange={(e) =>
                          setPercentageForLimitedAvailability(e.target.value)
                        }
                      />
                    </label>
                  </div>
                </div>
              </div>

              <h5
                style={{
                  paddingBottom: "0px",
                  marginBottom: "0px",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                Advanced
              </h5>
              <hr />

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    marginRight: "3%",
                  }}
                >
                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="allowedRoles"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Allowed Roles
                      <br />
                      <input
                        type="text"
                        name="allowedRoles"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={allowedRoles}
                        onChange={(e) => setAllowedRoles(e.target.value)}
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="allowedLocations"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Allowed Locations
                      <br />
                      <input
                        type="text"
                        name="allowedLocations"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={allowedLocations}
                        onChange={(e) => setAllowedLocations(e.target.value)}
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="startDate"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Start Date
                      <br />
                      <input
                        type="date"
                        name="startDate"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="distancelimit"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Distance limit
                      <br />
                      <input
                        type="number"
                        name="distancelimit"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={distancelimit}
                        onChange={(e) => setDistancelimit(e.target.value)}
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="distanceLimitperDay"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Distance Limit per Day
                      <br />
                      <input
                        type="number"
                        name="distanceLimitperDay"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={distanceLimitperDay}
                        onChange={(e) => setDistanceLimitperDay(e.target.value)}
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="distanceLimitperMonth"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Distance Limit per Month
                      <br />
                      <input
                        type="number"
                        name="distanceLimitperMonth"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={distanceLimitperMonth}
                        onChange={(e) =>
                          setDistanceLimitperMonth(e.target.value)
                        }
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="sipp"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      SIPP Code
                      <br />
                      <input
                        type="text"
                        name="sipp"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={sipp}
                        onChange={(e) => setSipp(e.target.value)}
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="driverYearsExperience"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Driver Years Experience
                      <br />
                      <input
                        type="number"
                        name="driverYearsExperience"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={driverYearsExperience}
                        onChange={(e) =>
                          setDriverYearsExperience(e.target.value)
                        }
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="enableFuelLevelTracking"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Enable Fuel Level Tracking
                      <br />
                      <div>
                        <label style={{ fontSize: "12px" }}>
                          <input
                            type="radio"
                            value="Yes"
                            name="enableFuelLevelTracking"
                            style={{ fontSize: "12px" }}
                            checked={enableFuelLevelTracking ? true : false}
                            onChange={(e) =>
                              seteEnableFuelLevelTracking(
                                e.target.value === "Yes" ? true : false
                              )
                            }
                          />{" "}
                          Yes
                        </label>
                        <label style={{ fontSize: "12px" }}>
                          <input
                            type="radio"
                            value="No"
                            name="enableFuelLevelTracking"
                            style={{ fontSize: "12px" }}
                            checked={enableFuelLevelTracking ? false : true}
                            onChange={(e) =>
                              seteEnableFuelLevelTracking(
                                e.target.value === "Yes" ? true : false
                              )
                            }
                          />{" "}
                          No
                        </label>
                      </div>
                    </label>
                  </div>
                </div>

                <div
                  style={{
                    width: "50%",
                  }}
                >
                  <div style={{ marginTop: "10px", visibility: "hidden" }}>
                    <label
                      htmlFor=" vehicle"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Branch
                      <br />
                      <input
                        type="text"
                        name="vehicle"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="restrictedLocations"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Restricted Locations
                      <br />
                      <input
                        type="text"
                        name="restrictedLocations"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={restrictedLocations}
                        onChange={(e) => setRestrictedLocations(e.target.value)}
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="spareQuantity"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Spare Quantity
                      <br />
                      <input
                        type="number"
                        name="spareQuantity"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={spareQuantity}
                        onChange={(e) => setSpareQuantity(e.target.value)}
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px", visibility: "hidden" }}>
                    <label
                      htmlFor="firstName"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Allowed Roles
                      <br />
                      <input
                        type="text"
                        name="firstName"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="distanceLimitperWeek"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Distance Limit per Week
                      <br />
                      <input
                        type="number"
                        name="distanceLimitperWeek"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={distanceLimitperWeek}
                        onChange={(e) =>
                          setDistanceLimitperWeek(e.target.value)
                        }
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="additionalChargeforExceededDistance"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Additional Charge for Exceeded Distance
                      <br />
                      <input
                        type="number"
                        name="additionalChargeforExceededDistance"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={additionalChargeforExceededDistance}
                        onChange={(e) =>
                          setAdditionalChargeforExceededDistance(e.target.value)
                        }
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px", visibility: "hidden" }}>
                    <label
                      htmlFor="firstName"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Allowed Roles
                      <br />
                      <input
                        type="text"
                        name="firstName"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px", visibility: "hidden" }}>
                    <label
                      htmlFor="firstName"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Allowed Roles
                      <br />
                      <input
                        type="text"
                        name="firstName"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="enableOdometerTracking"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Enable Odometer Tracking
                      <br />
                      <div>
                        <label style={{ fontSize: "12px" }}>
                          <input
                            type="radio"
                            value="Yes"
                            name="enableOdometerTracking"
                            style={{ fontSize: "12px" }}
                            checked={enableOdometerTracking ? true : false}
                            onChange={(e) =>
                              setEnableOdometerTracking(
                                e.target.value === "Yes" ? true : false
                              )
                            }
                          />{" "}
                          Yes
                        </label>
                        <label style={{ fontSize: "12px" }}>
                          <input
                            type="radio"
                            value="No"
                            name="enableOdometerTracking"
                            style={{ fontSize: "12px" }}
                            checked={enableOdometerTracking ? false : true}
                            onChange={(e) =>
                              setEnableOdometerTracking(
                                e.target.value === "Yes" ? true : false
                              )
                            }
                          />{" "}
                          No
                        </label>
                      </div>
                    </label>
                  </div>
                </div>
              </div>

              <h5
                style={{
                  paddingBottom: "0px",
                  marginBottom: "0px",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => setIsWebsite(!isWebsite)}
              >
                {isWebsite ? (
                  <ExpandMoreIcon sx={{ fontSize: "20px" }} />
                ) : (
                  <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
                )}{" "}
                Website
              </h5>
              <hr />

              {isWebsite && (
                <>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{
                        width: "50%",
                        marginRight: "3%",
                      }}
                    >
                      <div style={{ marginTop: "10px" }}>
                        <label
                          htmlFor="similarVehicleMakeId"
                          style={{ fontSize: "12px", fontWeight: "bold" }}
                        >
                          Similar Vehicle Make
                          <br />
                          <input
                            type="number"
                            name="similarVehicleMakeId"
                            style={{
                              width: "100%",
                              fontSize: "14px",
                              paddingBlock: "5px",
                            }}
                            value={similarVehicleMakeId}
                            onChange={(e) =>
                              setSimilarVehicleMakeId(e.target.value)
                            }
                          />
                        </label>
                      </div>
                    </div>

                    <div
                      style={{
                        width: "50%",
                      }}
                    >
                      <div style={{ marginTop: "10px" }}>
                        <label
                          htmlFor="similarVehicleModelId"
                          style={{ fontSize: "12px", fontWeight: "bold" }}
                        >
                          Similar Vehicle Model
                          <br />
                          <input
                            type="number"
                            name="similarVehicleModelId"
                            style={{
                              width: "100%",
                              fontSize: "14px",
                              paddingBlock: "5px",
                            }}
                            value={similarVehicleModelId}
                            onChange={(e) =>
                              setSimilarVehicleModelId(e.target.value)
                            }
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="features"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Features
                        <br />
                        <Select
                          isMulti
                          className="basic-single"
                          classNamePrefix="select"
                          isDisabled={false}
                          isLoading={false}
                          isClearable={false}
                          isRtl={false}
                          isSearchable={false}
                          name="features"
                          value={features}
                          onChange={setFeatures}
                          options={featuresOptions}
                          required
                        />
                      </label>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="label"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Label
                        <br />
                        <input
                          type="text"
                          name="label"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={label}
                          onChange={(e) => setLabel(e.target.value)}
                        />
                      </label>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                        Short Description
                      </label>

                      <br />
                      <label style={{ fontSize: "12px" }}>
                        <Editor
                          editorState={editorStateShort}
                          wrapperClassName="wrapper-class"
                          editorClassName="editor-class"
                          toolbarClassName="toolbar-class"
                          onEditorStateChange={setEditorStateShort}
                          toolbar={{
                            inline: { inDropdown: false },
                            list: { inDropdown: true },
                            textAlign: { inDropdown: true },
                            link: { inDropdown: true },
                            history: { inDropdown: true },
                          }}
                        />
                      </label>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                        Description
                      </label>
                      <label style={{ fontSize: "12px" }}>
                        <br />
                        <Editor
                          editorState={editorStateDescription}
                          wrapperClassName="wrapper-class"
                          editorClassName="editor-class"
                          toolbarClassName="toolbar-class"
                          onEditorStateChange={setEditorStateDescription}
                          toolbar={{
                            inline: { inDropdown: false },
                            list: { inDropdown: true },
                            textAlign: { inDropdown: true },
                            link: { inDropdown: true },
                            history: { inDropdown: true },
                          }}
                        />
                      </label>
                    </div>
                  </div>
                </>
              )}

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  gap: "5px",
                  marginTop: "20px",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: colorConfigs.topbar.bg,
                    color: "white",
                    borderRadius: "0",
                    border: "none",
                    textTransform: "capitalize",
                  }}
                  type="submit"
                >
                  Save
                </Button>

                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#e0dee0",
                    color: "black",
                    borderRadius: "0",
                    border: "none",
                    textTransform: "capitalize",
                  }}
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
              </div>
            </form>
          </div>
        </div>

        <div
          style={{
            width: "25%",
          }}
        ></div>
      </div>

      <Dialog
        open={showWebcam}
        maxWidth="md"
        fullScreen={false}
        PaperProps={{
          style: {
            // width: "35%",
            marginTop: "-16%",
          },
        }}
      >
        <DialogContent sx={{ padding: "0px" }}>
          <Webcam
            width={480}
            height={320}
            ref={webRef}
            screenshotFormat="image/png"
            audio={false}
          />

          <div
            style={{
              width: "100%",
              height: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingBlock: "8px",
              paddingLeft: "16px",
              paddingRight: "16px",
              backgroundColor: "#eeeeee",
            }}
          >
            <div style={{ width: "50%" }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#e0dee0",
                  color: colorConfigs.sidebar.color,
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                  fontSize: "12px",
                  "&: hover": {
                    backgroundColor: "#e0dee0",
                    color: colorConfigs.sidebar.color,
                  },
                }}
                onClick={() => setShowWebcam(false)}
              >
                Cancel
              </Button>
            </div>

            <div
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                  fontSize: "12px",
                  "&: hover": {
                    backgroundColor: colorConfigs.topbar.bg,
                    color: "white",
                  },
                }}
                onClick={() => {
                  if (webRef.current !== null) {
                    setFile(null);
                    //@ts-ignore
                    const imageSrc = webRef.current.getScreenshot();
                    setFile(null);
                    setFilePath(imageSrc);
                    setShowWebcam(false);
                  }
                }}
              >
                Capture an Image
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={imagePopup}
        maxWidth="md"
        fullScreen={false}
        PaperProps={{
          style: {
            marginTop: "-14%",
          },
        }}
      >
        <DialogTitle sx={{ padding: "0px", width: "100%" }}>
          <div
            style={{
              height: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 16px 8px 16px",
            }}
          >
            <p style={{ fontSize: "14px", marginLeft: "5px" }}>Vehicle Class Image</p>
            <CloseIcon
              sx={{ fontSize: "14px", marginRight: "5px" }}
              onClick={() => setImagePopup(false)}
            />
          </div>
          <hr style={{ marginBlock: "0px" }} />
          <div
            style={{
              width: "100%",
              height: "5%",
              backgroundColor: colorConfigs.sidebar.bg,
              paddingLeft: "8px",
              fontSize: "14px",
            }}
          >
            Preview
          </div>
          <hr style={{ marginTop: "0px" }} />
        </DialogTitle>

        <DialogContent sx={{ padding: "5px" }}>
          {(file || filePath) && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "5px",
              }}
            >
              {file && (
                <img
                  src={URL.createObjectURL(file[0])}
                  width={700}
                  height={350}
                  alt="vehicle"
                />
              )}

              {filePath && (
                <img width={700} height={350} src={filePath} alt="vehicle" />
              )}
            </div>
          )}
        </DialogContent>
      </Dialog>

      <PleaseWaitPopup pleaseWaitPopup={pleaseWaitPopup} />
    </>
  );
}