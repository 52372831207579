import { HashRouter as Router, Route, Routes } from "react-router-dom";
import MainLayout from "./components/layout/MainLayout";
import { adminRoutes, routes } from "./routes";
import "./App.css";
import { AddVehicles } from "./pages/fleet/vehicles/AddVehicles";
import { AddFeature } from "./pages/settings/fleet/features/AddFeature"
import { EditFeature } from "./pages/settings/fleet/features/EditFeature"
import { PageNotFound } from "./pages/notFound/PageNotFound";
import { EditVehicles } from "./pages/fleet/vehicles/EditVehicles";
import { AddRepairOrder } from "./pages/fleet/repairOrder/AddRepairOrder";
import { EditRepariOrder } from "./pages/fleet/repairOrder/EditRepariOrder";
import { AddCustomer } from "./pages/contacts/customers/AddCustomer";
import { EditCustomer } from "./pages/contacts/customers/EditCustomer";
import { AdminMainLayout } from "./components/layout/AdminMainLayout";
import AddBranch from "./pages/settings/fleet/branches/AddBranch";
import EditBranch from "./pages/settings/fleet/branches/EditBranch";
import AddReservation from "./pages/carrental/reservations/AddReservation";
import CreateRepairOrder from "./pages/fleet/maintenance/CreateRepairOrder";
import { AddSeasons } from "./pages/settings/carrental/seasons/AddSeasons";
import { EditSeasons } from "./pages/settings/carrental/seasons/EditSeasons";
import { AddRates } from "./pages/settings/carrental/rates/AddRates";
import { EditRates } from "./pages/settings/carrental/rates/EditRates";
import { AddUser } from "./pages/settings/usermanagement/users/AddUser";
import EditVehicleClass from "./pages/settings/fleet/vehicleClass/EditVehicleClass";
import { UpdateUsers } from "./pages/settings/usermanagement/users/UpdateUsers";

import AddVehicleClass from "./pages/settings/fleet/vehicleClass/AddVehicleClass";
import AddAdditionalCharges from "./pages/settings/carrental/additionalCharges/AddAdditionalCharges";
import EditAdditionalCharges from "./pages/settings/carrental/additionalCharges/EditAdditionalCharges";

import AddDiscounts from "./pages/settings/carrental/discounts/AddDiscounts";
import EditDiscounts from "./pages/settings/carrental/discounts/EditDiscounts";
import EditReservation from "./pages/carrental/reservations/EditReservation";
import AddSecurityDeposits from "./pages/settings/carrental/securityDeposits/AddSecurityDeposits";
import EditSecurityDeposits from "./pages/settings/carrental/securityDeposits/EditSecurityDeposits";
import { AddEmailTemplate } from "./pages/settings/carrental/emailTemplates/AddEmailTemplate";
import EditEmailTemplate from "./pages/settings/carrental/emailTemplates/EditEmailTemplate";
import { AddVehicleType } from "./pages/settings/fleet/vehicleTypes/AddVehicleType";
import { EditVehicleType } from "./pages/settings/fleet/vehicleTypes/EditVehicleType";
import { AddVehicleModel } from "./pages/settings/fleet/vehicleModel/AddVehicleModel";
import { EditVehicleModel } from "./pages/settings/fleet/vehicleModel/EditVehicleModel";
import { AddVehicleMake } from "./pages/settings/fleet/vehicleMake/AddVehicleMake";
import { EditVehicleMake } from "./pages/settings/fleet/vehicleMake/EditVehicleMake";
import { AddVehicleEngine } from "./pages/settings/fleet/vehicleEngines/AddVehicleEngine";
import { EditVehicleEngine } from "./pages/settings/fleet/vehicleEngines/EditVehicleEngine";
import { AddFleetLocation } from "./pages/settings/fleet/fleetLocations/AddFleetLocation";
import { EditFleetLocation } from "./pages/settings/fleet/fleetLocations/EditFleetLocation";
import Layout from "./components/layout/Layout";
import Login from "./components/login/Login";
import RequireAuth from "./components/login/RequireAuth";
import { AuthProvider } from "./components/context/AuthProvider";
import PrintQuotes from "./pages/carrental/reservations/PrintQuotes";
import PaymentGatewaysForm from "./pages/settings/paymentGateways/gateways/PaymentGatewaysForm";
import EditPaymentGatewaysForm from "./pages/settings/paymentGateways/gateways/EditPaymentGatewaysForm";
import ForgotPassword from "./components/login/ForgotPassword";
import { AccessControlProvider } from "./components/context/AccessControlProvider";

const Roles = {
  Admin: "Admin",
}

function App() {
  return (
    <Router>
      <AuthProvider>
        <AccessControlProvider>
        <Routes>
          <Route path="*" element={<PageNotFound />} />

          <Route path="/" element={<Layout />}>
            <Route path="login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />

            <Route element={<RequireAuth roles={[Roles.Admin]} />}>
              <Route path="/" element={<MainLayout />}>
                {routes}
                <Route path="/fleet/vehicles/addvehicles" element={<AddVehicles />} />
                <Route
                  path="/fleet/vehicles/editvehicles"
                  element={<EditVehicles />}
                />
                <Route
                  path="/fleet/vehicles/addrepairorder"
                  element={<AddRepairOrder />}
                />
                <Route
                  path="/fleet/vehicles/editrepairorder"
                  element={<EditRepariOrder />}
                />
                <Route
                  path="/fleet/maintenance/createrepairorder"
                  element={<CreateRepairOrder />}
                />
                <Route
                  path="/contacts/customers/addcustomer"
                  element={<AddCustomer />}
                />
                <Route
                  path="/contacts/customers/editcustomer"
                  element={<EditCustomer />}
                />
                <Route
                  path="/carrental/reservations/addreservation"
                  element={<AddReservation />}
                />
                <Route
                  path="/carrental/reservations/editreservation"
                  element={<EditReservation />}
                />
              </Route>
            </Route>

            <Route element={<RequireAuth roles={[Roles.Admin]} />}>
              <Route path="/admin" element={<AdminMainLayout />}>
                {adminRoutes}
                <Route
                  path="/admin/usermanagement/users/adduser"
                  element={<AddUser />}
                />
                <Route
                  path="/admin/usermanagement/users/edituser"
                  element={<UpdateUsers />}
                />
                <Route
                  path="/admin/fleet/branches/addbranch"
                  element={<AddBranch />}
                />
                <Route
                  path="/admin/fleet/branches/editbranch"
                  element={<EditBranch />}
                />
                <Route
                  path="/admin/fleet/vehicleclasses/addvehicleclasses"
                  element={<AddVehicleClass />}
                />
                <Route
                  path="/admin/fleet/vehicleclasses/editvehicleclasses"
                  element={<EditVehicleClass />}
                />
                <Route
                  path="/admin/carrental/seasons/addseason"
                  element={<AddSeasons />}
                />
                <Route
                  path="/admin/carrental/seasons/editseason"
                  element={<EditSeasons />}
                />
                <Route
                  path="/admin/carrental/rates/addrates"
                  element={<AddRates />}
                />
                <Route
                  path="/admin/carrental/rates/editrates"
                  element={<EditRates />}
                />
                <Route
                  path="/admin/carrental/additionalcharges/addadditionalCharges"
                  element={<AddAdditionalCharges />}
                />
                <Route
                  path="/admin/carrental/additionalcharges/editadditionalCharges"
                  element={<EditAdditionalCharges />}
                />
                <Route
                  path="/admin/carrental/discounts/adddiscount"
                  element={<AddDiscounts />}
                />
                <Route
                  path="/admin/carrental/discounts/editdiscount"
                  element={<EditDiscounts />}
                />
                <Route
                  path="/admin/carrental/securitydeposits/addsecuritydeposit"
                  element={<AddSecurityDeposits />}
                />
                <Route
                  path="/admin/carrental/securitydeposits/editsecuritydeposit"
                  element={<EditSecurityDeposits />}
                />
                <Route
                  path="/admin/carrental/emailtemplates/addemailtemplate"
                  element={<AddEmailTemplate />}
                />
                <Route
                  path="/admin/carrental/emailTemplates/editTemplates"
                  element={<EditEmailTemplate />}
                />
                <Route
                  path="/admin/fleet/vehicletypes/addvehicletype"
                  element={<AddVehicleType />}
                />
                <Route
                  path="/admin/fleet/vehicleTypes/editVehicleType"
                  element={<EditVehicleType />}
                />
                <Route
                  path="/admin/fleet/vehiclemake/addvehiclemake"
                  element={<AddVehicleMake />}
                />
                <Route
                  path="/admin/fleet/vehiclemake/editVehicleMake"
                  element={<EditVehicleMake />}
                />
                <Route
                  path="/admin/fleet/vehiclemodel/addvehiclemodel"
                  element={<AddVehicleModel />}
                />
                <Route
                  path="/admin/fleet/vehiclemodel/editvehiclemodel"
                  element={<EditVehicleModel />}
                />
                <Route
                  path="/admin/fleet/vehicleengines/addvehicleengine"
                  element={<AddVehicleEngine />}
                />
                <Route
                  path="/admin/fleet/vehicleengines/editvehicleengine"
                  element={<EditVehicleEngine />}
                />
                <Route
                  path="/admin/fleet/features/addfeature"
                  element={<AddFeature />}
                />
                <Route
                  path="/admin/fleet/features/editfeature"
                  element={<EditFeature />}
                />
                <Route
                  path="/admin/fleet/fleetlocations/addfleetlocation"
                  element={<AddFleetLocation />}
                />
                <Route
                  path="/admin/fleet/fleetlocations/editfleetlocation"
                  element={<EditFleetLocation />}
                />
                <Route
                  path="/admin/paymentgateways/paymentgatewaysform"
                  element={<PaymentGatewaysForm />}
                />
                <Route
                  path="/admin/paymentgateways/editpaymentgatewaysform"
                  element={<EditPaymentGatewaysForm />}
                />
              </Route>
            </Route>

          </Route>

          <Route
            path="/carrental/reservations/print-quote"
            element={<PrintQuotes />}
          />

        </Routes>
        </AccessControlProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
